var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"bsModalUpdateMerchantCustomFee","aria-labelledby":"bsModalUpdateMerchantCustomFeeLabel","data-backdrop":"static","data-keyboard":"false","data-toggle":"modal","role":"dialog","tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"close",attrs:{"aria-label":"Close","type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("× ")])]),_c('h4',{staticClass:"modal-title",attrs:{"id":"bsModalUpdateMerchantCustomFeeLabel"}},[_vm._v("Update Merchant Custom Fee")])]),_c('div',{staticClass:"modal-body"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.form.Key === 'MerchantID')?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"MerchantID","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"merchantID"},[_vm._v("Merchant")]),_c('v-select',{staticStyle:{"min-width":"200px"},attrs:{"options":_vm.merchantsList,"reduce":function (m) { return m.ID; },"label":"Name","placeholder":"Select Merchant"},model:{value:(_vm.form.Value),callback:function ($$v) {_vm.$set(_vm.form, "Value", $$v)},expression:"form.Value"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.form.Key === 'AltCoinID')?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"AltCoinID","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"altCoinID"}},[_vm._v("AltCoin")]),_c('v-select',{staticStyle:{"min-width":"200px"},attrs:{"options":_vm.formattedAltCoins,"reduce":function (a) { return a.ID; },"label":"Name","placeholder":"Select AltCoin"},model:{value:(_vm.form.Value),callback:function ($$v) {_vm.$set(_vm.form, "Value", $$v)},expression:"form.Value"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.form.Key !== 'AltCoinID' && _vm.form.Key !== 'MerchantID')?_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":_vm.form.Key,"rules":{
                  required: true,
                  regex: _vm.regex
                },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.form.Key))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Value),expression:"form.Value"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.form.Value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Value", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary btn-flat",attrs:{"disabled":_vm.isLoading,"type":"submit"}},[_c('span',[_vm._v("Save")]),(_vm.isLoading)?_c('span',[_c('i',{staticClass:"fa fa-circle-o-notch fa-spin ml-8"})]):_vm._e()])])],1)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }