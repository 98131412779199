<template>
  <div id="bsModalUpdateMerchantCustomFee" aria-labelledby="bsModalUpdateMerchantCustomFeeLabel" class="modal fade"
       data-backdrop="static" data-keyboard="false"
       data-toggle="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span
              aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalUpdateMerchantCustomFeeLabel" class="modal-title">Update Merchant Custom Fee</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="MerchantID" rules="required"
                                  tag="div" v-if="form.Key === 'MerchantID'">
                <label class="merchantID">Merchant</label>
                <v-select v-model="form.Value" :options="merchantsList" style="min-width: 200px"
                          :reduce="m => m.ID" label="Name" placeholder="Select Merchant"></v-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="AltCoinID" rules="required" tag="div"
                v-if="form.Key === 'AltCoinID'">
                <label for="altCoinID">AltCoin</label>
                <v-select v-model="form.Value" :options="formattedAltCoins" style="min-width: 200px"
                          :reduce="a => a.ID" label="Name" placeholder="Select AltCoin"></v-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" :name="form.Key" :rules="{
                    required: true,
                    regex: regex
                  }" tag="div" v-if="form.Key !== 'AltCoinID' && form.Key !== 'MerchantID'">
                <label>{{ form.Key }}</label>
                <input type="text" v-model="form.Value" class="form-control">
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span>Save</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin ml-8"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { logout, saveNotification } from "@/lib/utils";

export default {
  name: "UpdateMerchantCustomFeeModal",
  data() {
    return {
      isLoading: false,
      form: {
        MerchantID: "",
        APIKey: "",
        ID: "",
        Key: "",
        Value: "",
        output: "json",
      },
      regex: /^\d*\.?\d{1,8}$/,
      merchantsList: [],
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "onlineAltCoins",
    ]),
    formattedAltCoins() {
      return this.onlineAltCoins.map(a => {
        return {
          ...a,
          Name: a.Name + " (" + a.LongName + ")",
        };
      });
    },
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      const payload = JSON.parse(JSON.stringify(this.form));
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=MerchantFeesCustomUpdate`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        this.isLoading = false;
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.confirm();
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    confirm: function () {
      this.confirmHook();
      this.close();
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      this.$refs.observer.reset();
      $("#bsModalUpdateMerchantCustomFee").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("update-merchant-custom-fee-modal", (args) => {
      this.merchantsList = args.merchantsList.map(m => {
        return {
          ...m,
          Name: m.ShopTitle + " (" + m.OwnerNickName + ")",
        };
      });
      const data = args.data;
      this.form = {
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: data.ID,
        Key: data.Key,
        Value: data.Value,
        output: "json",
      };
      if (args.data.decimal) {
        if (args.data.decimal === 8) {
          this.regex = /^\d*\.?\d{1,8}$/;
        } else if (args.data.decimal === 2) {
          this.regex = /^\d*\.?\d{1,2}$/;
        } else {
          this.regex = /^\d*\.?\d{1,4}$/;
        }
      }
      this.confirmHook = args.confirm;
      $("#bsModalUpdateMerchantCustomFee").modal("show");
      this.valueLabel = args.valueLabel;
      this.type = args.type;
      if (this.type === "select") {
        if (typeof this.form.Value === "boolean") {
          this.form.Value = this.form.Value ? 1 : 0;
        }
        this.options = args.options ? args.options : [];
      }
    });
  },
};
</script>

<style scoped></style>
