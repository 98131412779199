<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">Merchant Fees Custom</h3>
          <div class="flex flex-col-sm gap-8 align-items-center">
            <button class="btn btn-flat btn-primary btn-sm" @click="launchMerchantFeeTemplateModal()">Merchant Fee
              Template</button>
            <button class="btn btn-flat btn-primary btn-sm" @click="addMerchantFee()">Add Merchant Fee</button>
            <v-select v-model="merchantID" :options="merchants" :reduce="merchant => merchant.ID" label="ShopTitle"
              placeholder="Select Merchant" style="min-width: 200px;"></v-select>
            <v-select v-model="altCoinID" :options="coins" :reduce="coin => coin.ID" label="Name"
              placeholder="Select AltCoin" style="min-width: 200px;"></v-select>
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>ID</th>
                <th>Merchant</th>
                <th>Coin</th>
                <th class="pointer" @click="sort('MinerFee')">
                  Miner Fee
                  <span v-if="sortKey === 'MinerFee'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('Percentage')">
                  Percentage
                  <span v-if="sortKey === 'Percentage'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('MinerFeeAfter')">
                  Miner Fee After
                  <span v-if="sortKey === 'MinerFeeAfter'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('PercentageAfter')">
                  Percentage After
                  <span v-if="sortKey === 'PercentageAfter'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('FiatPayoutFee')">
                  Fiat Payout Fee
                  <span v-if="sortKey === 'FiatPayoutFee'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('CCRate')">
                  CCRate
                  <span v-if="sortKey === 'CCRate'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('CCFixedFee')">
                  CCFixedFee
                  <span v-if="sortKey === 'CCFixedFee'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('SWAPFee')">
                  SWAPFee
                  <span v-if="sortKey === 'SWAPFee'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('SWAPMinerFee')">
                  SWAPMinerFee
                  <span v-if="sortKey === 'SWAPMinerFee'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('SWAPFeeRange1')">
                  SWAPFeeRange1
                  <span v-if="sortKey === 'SWAPFeeRange1'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('SWAPFeeRange2')">
                  SWAPFeeRange2
                  <span v-if="sortKey === 'SWAPFeeRange2'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('SWAPFeeRange3')">
                  SWAPFeeRange3
                  <span v-if="sortKey === 'SWAPFeeRange3'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('SWAPFeeRange4')">
                  SWAPFeeRange4
                  <span v-if="sortKey === 'SWAPFeeRange4'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('CreatedOn')">
                  Created On
                  <span v-if="sortKey === 'CreatedOn'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('UpdatedOn')">
                  Updated On
                  <span v-if="sortKey === 'UpdatedOn'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index" @click="showFeeCalculator(record)">
                  <td>{{ record.ID }}</td>
                  <td class="no-wrap">
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'MerchantID', 0)">
                      {{ record.ShopTitle + ' (' + record.OwnerNickName + ')' }}
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'AltCoinID', 0)">
                      {{ record.Name + ' (' + record.LongName + ')' }}
                    </span>
                  </td>
                  <td class="no-wrap">
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'MinerFee', 8)">
                      {{ toPlainStr(record.MinerFee, 8) }}
                    </span>
                    <span class="pointer ml-8" @click="toConverter(toFixed(record.MinerFee), record.AltCoinID)">
                      <i class="fa fa-copy"></i>
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'Percentage')">
                      {{ record.Percentage }}
                    </span>
                  </td>
                  <td class="no-wrap">
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'MinerFeeAfter', 8)">
                      {{ toPlainStr(record.MinerFeeAfter, 8) }}
                    </span>
                    <span class="pointer ml-8" @click="toConverter(toFixed(record.MinerFeeAfter), record.AltCoinID)">
                      <i class="fa fa-copy"></i>
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'PercentageAfter')">
                      {{ record.PercentageAfter }}
                    </span>
                  </td>
                  <td class="no-wrap">
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'FiatPayoutFee', 8)">
                      {{ toPlainStr(record.FiatPayoutFee, 8) }}
                    </span>
                    <span class="pointer ml-8" @click="toConverter(toFixed(record.FiatPayoutFee), record.AltCoinID)">
                      <i class="fa fa-copy"></i>
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'CCRate')">
                      {{ record.CCRate }}
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'CCFixedFee')">
                      {{ record.CCFixedFee }}
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'SWAPFee', 2)">
                      {{ toPlainStr(record.SWAPFee) }}
                    </span>
                  </td>
                  <td class="no-wrap">
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'SWAPMinerFee', 8)">
                      {{ toPlainStr(record.SWAPMinerFee, 8) }}
                    </span>
                    <span class="pointer ml-8" @click="toConverter(toFixed(record.SWAPMinerFee), record.AltCoinID)">
                      <i class="fa fa-copy"></i>
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'SWAPFeeRange1', 2)">
                      {{ toPlainStr(record.SWAPFeeRange1) }}
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'SWAPFeeRange2', 2)">
                      {{ toPlainStr(record.SWAPFeeRange2) }}
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'SWAPFeeRange3', 2)">
                      {{ toPlainStr(record.SWAPFeeRange3) }}
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdateMerchantCustomFeeModal(record, 'SWAPFeeRange4', 2)">
                      {{ toPlainStr(record.SWAPFeeRange4) }}
                    </span>
                  </td>
                  <td class="no-wrap">{{ formatDate(record.CreatedOn) }}</td>
                  <td class="no-wrap">{{ formatDate(record.UpdatedOn) }}</td>
                  <td class="no-wrap pr-16">
                    <button class="btn btn-primary btn-flat btn-sm mr-4" @click="cloneMerchantCustomFee(record)">
                      Clone
                    </button>
                    <button class="btn btn-danger btn-flat btn-sm" @click="deleteMerchantCustomFee(record)">
                      Delete
                    </button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="18">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
    <div class="box box-default" v-if="isShowCalculatorForm">
      <div class="box-header">
        <div class="flex align-items-center justify-between">
          <h3 class="box-title">Calculate Fees Custom</h3>
        </div>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-6">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider v-slot="{ errors }" class="form-group" name="MerchantID" rules="required" tag="div">
                  <label class="merchantID">Merchant</label>
                  <select id="merchantID" v-model="form.MerchantID" class="form-control">
                    <option v-for="(record, index) in merchantsList" :key="index" :value="record.ID">
                      {{ record.ShopTitle + ' (' + record.OwnerNickName + ')' }}
                    </option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="AltCoinID" rules="required" tag="div">
                  <label for="altCoinID">AltCoin</label>
                  <select id="altCoinID" v-model="form.AltCoinID" class="form-control">
                    <option v-for="(record, index) in onlineAltCoins" :key="index" :value="record.ID">
                      {{ record.Name + ' (' + record.LongName + ')' }}
                    </option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="inputCurrency" rules="required"
                  tag="div">
                  <label for="inputCurrency">InputCurrency</label>
                  <select id="inputCurrency" v-model="form.inputCurrency" class="form-control">
                    <option value="">Select Input Currency</option>
                    <option v-for="(record, index) in inputCurrenciesList" :key="index" :value="record.ID">
                      {{ record.Name + ' (' + record.LongName + ')' }}
                    </option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="form-group" name="amount" rules="required" tag="div">
                  <label for="amount">Amount</label>
                  <input type="text" id="amount" v-model="form.Amount" class="form-control">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="text-right">
                  <button :disabled="isBtnLoading" class="btn btn-primary btn-flat" type="submit">
                    <span>Calculate</span>
                    <span v-if="isBtnLoading"><i class="fa fa-circle-o-notch fa-spin ml-8"></i></span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <div v-if="message" v-html="message"></div>
      </div>
    </div>
    <update-merchant-custom-fee-modal></update-merchant-custom-fee-modal>
    <!-- Merchant Fee Template -->
    <merchant-fee-template-modal></merchant-fee-template-modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { SAVE_CONVERTER, START_LOADING, STOP_LOADING } from "@/store/keys";
import { formatDate, saveNotification, toFixed } from "@/lib/utils";
import UpdateMerchantCustomFeeModal from "@/components/modals/UpdateMerchantCustomFeeModal";
import * as _ from "lodash";
import MerchantFeeTemplateModal from "@/components/modals/MerchantFeeTemplateModal";

export default {
  name: "MerchantFeesCustom",
  components: {
    MerchantFeeTemplateModal,
    Pagination,
    UpdateMerchantCustomFeeModal,
  },
  data() {
    return {
      altCoinID: "",
      merchantID: "",
      records: [],
      coins: [],
      merchants: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: 40,
      },
      isShowCalculatorForm: false,
      merchantsList: [],
      form: {
        MerchantID: "",
        AltCoinID: "",
        Amount: "",
      },
      selectedRecord: null,
      message: "",
      isBtnLoading: false,
      sortKey: "CreatedOn",
      order: "desc",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
      "onlineAltCoins",
      "converter",
    ]),
    inputCurrenciesList() {
      return this.onlineAltCoins.filter(coin => coin.isInputCurrency);
    },
  },
  watch: {
    altCoinID: {
      handler(val) {
        console.log(val);
        if (val === null) {
          this.altCoinID = "";
        }
        this.getPaginatedRecords(1);
      },
      deep: true,
    },
    merchantID(val) {
      if (val === null) {
        this.merchantID = "";
      }
      this.getPaginatedRecords(1);
    },
  },
  methods: {
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getPaginatedRecords(1);
    },
    getPaginatedRecords(page = 1, isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=merchant_fees_custom`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          altCoinID: this.altCoinID,
          merchantID: this.merchantID,
          limit: Number.parseInt(this.meta.limit),
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.coins = response.data.data.coins;
          this.merchants = response.data.data.merchants;
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getAllMerchants() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=MerchantsList`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then((response) => {
        this.merchantsList = response.data.data;
        if (this.merchantsList.length > 0) {
          this.merchantsList = _.orderBy(this.merchantsList, ["ShopTitle"], ["asc"]);
        }
      }).catch(error => {
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
    addMerchantFee() {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "MerchantFeesCustomInsert",
          MerchantID: 1,
          APIKey: "_",
          AltCoinID: 1,
          output: "json",
        },
      }).then(response => {
        if (response.data.status_code === 200) {
          if (response.data.result.toLowerCase() === "success") {
            this.getAllMerchants();
            setTimeout(() => {
              this.getPaginatedRecords();
            }, 5000);
          }
        }
      }).catch(error => {
        console.log(error);
      });
    },
    formatVal(record, key, decimal) {
      let val = typeof (record[key]) === "number" ? Number(record[key]).toFixed(decimal) : record[key];
      if ((key === "MerchantID" || key === "AltCoinID") && val === "0") {
        val = "";
      }
      return val;
    },
    launchUpdateMerchantCustomFeeModal(record, key, decimal = 4) {
      this.$bus.$emit("update-merchant-custom-fee-modal", {
        data: {
          ID: record.ID,
          Key: key,
          Value: this.formatVal(record, key, decimal),
          decimal,
        },
        merchantsList: this.merchantsList,
        confirm: (id) => {
          this.getPaginatedRecords(this.meta.page);
        },
      });
    },
    cloneMerchantCustomFee(record) {
      const index = this.records.indexOf(record);
      if (index !== -1) {
        this.$swal.fire({
          text: "Are you sure want to clone this record?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00a65a",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          focusCancel: true,
        }).then(({ value }) => {
          if (value && value === true) {
            this.$swal.fire({
              title: "",
              text: "Please wait...",
              showConfirmButton: false,
              backdrop: true,
            });
            const apiBaseUrl = process.env.VUE_APP_API_URL;
            const payload = {
              Call: "CloneFee",
              ID: record.ID,
              MerchantID: this.currentUser.ID,
              APIKey: this.currentUser.APIKey,
              output: "json",
            };
            this.$http.get(`${apiBaseUrl}/v2REAPI`, {
              params: payload,
              withCredentials: true,
            }).then(response => {
              this.$swal.close();
              if (response.data.status_code === 200) {
                if (response.data.result.toLowerCase() === "success") {
                  this.$toast.fire("", response.data.message, "success");
                  saveNotification(response.data.message);
                  this.getPaginatedRecords(this.meta.page, false);
                }
              }
            }).catch(error => {
              this.$swal.close();
              console.log(error);
            });
          }
        });
      }
    },
    deleteMerchantCustomFee(record) {
      const index = this.records.indexOf(record);
      if (index !== -1) {
        this.$swal.fire({
          text: "Are you sure want to delete this record?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00a65a",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          focusCancel: true,
        }).then(({ value }) => {
          if (value && value === true) {
            this.$swal.fire({
              title: "",
              text: "Please wait...",
              showConfirmButton: false,
              backdrop: true,
            });
            const apiBaseUrl = process.env.VUE_APP_API_URL;
            const payload = {
              Call: "DeployFeeTemplate",
              MerchantID: record.MerchantID,
              DeleteID: record.ID,
              APIKey: 1,
              RefundAddress: "AAAAAA",
              output: "json",
            };
            this.$http.get(`${apiBaseUrl}/v2REAPI`, {
              params: payload,
              withCredentials: true,
            }).then(response => {
              this.$swal.close();
              if (response.data.status_code === 200) {
                if (response.data.result.toLowerCase() === "success") {
                  this.$toast.fire("", response.data.message, "success");
                  saveNotification(response.data.message);
                  this.records.splice(index, 1);
                }
              }
            }).catch(error => {
              this.$swal.close();
              console.log(error);
            });
          }
        });
      }
    },
    launchMerchantFeeTemplateModal() {
      this.$bus.$emit("merchantFeeTemplateModal", {
        merchantsList: this.merchantsList,
        confirm: () => {
          this.getPaginatedRecords();
        },
      });
    },
    formatCurrencyShortName(shortName) {
      switch (shortName.toLowerCase()) {
        case "eur":
          return "VEU";
        case "usd":
          return "VUS";
        case "gbp":
          return "VPO";
        case "cad":
          return "VCA";
        default:
          return shortName.toUpperCase();
      }
    },
    showFeeCalculator(record) {
      this.message = "";
      let payoutCurrencyShortName = record.PayoutCurrencyShortName;
      payoutCurrencyShortName = this.formatCurrencyShortName(payoutCurrencyShortName);
      const inputCurrency = this._.find(this.inputCurrenciesList, { ShortName: payoutCurrencyShortName });
      this.form = {
        MerchantID: record.MerchantID,
        AltCoinID: record.AltCoinID,
        Amount: 100,
        inputCurrency: inputCurrency.ID,
      };
      this.selectedRecord = record;
      this.isShowCalculatorForm = true;
      this.onSubmit();
    },
    onSubmit() {
      this.isBtnLoading = true;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: {
          Call: "CalculateFeesCustom",
          MerchantID: this.selectedRecord.MerchantID,
          APIKey: "_",
          AltCoinID: this.form.AltCoinID,
          ID: this.selectedRecord.ID,
          Amount: this.form.Amount,
          inputCurrency: this.form.inputCurrency,
          output: "json",
        },
      }).then(response => {
        this.isBtnLoading = false;
        this.message = response.data.message;
      }).catch(error => {
        this.isBtnLoading = false;
        console.log(error);
      });
    },
    toConverter(amount, altCoinID) {
      const form = { ...this.converter };
      form.source = amount;
      form.sourceAltCoinId = altCoinID;
      this.$store.commit(SAVE_CONVERTER, form);
      this.copyToClipboard(amount);
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(() => {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, (err) => {
        console.error("Async: Could not copy text: ", err);
      });
    },
    toFixed(val) {
      return toFixed(val);
    },
    toPlainStr(num, decimal = 2) {
      return Number(num).toFixed(decimal).toLocaleString("en-US", { useGrouping: false, maximumFractionDigits: decimal });
    },
  },
  mounted() {
    this.getAllMerchants();
    if (this.$route.query && this.$route.query.merchant_id) {
      this.merchantID = this.$route.query.merchant_id;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      });
    }
    this.getPaginatedRecords();
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
