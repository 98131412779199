<template>
  <div id="bsModalMerchantFeeTemplate" aria-labelledby="bsModalMerchantFeeTemplateLabel" class="modal fade"
    data-backdrop="static" data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalMerchantFeeTemplateLabel" class="modal-title">Merchant Fee Template</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="MerchantID" rules="required" tag="div">
                <label class="merchantID">Merchant</label>
                <v-select v-model="form.MerchantID" :options="merchantsList" :reduce="merchant => merchant.ID"
                  :getOptionLabel="(option) => { return option.ShopTitle + ' (' + option.OwnerNickName + ')' }"
                  placeholder="Select Merchant" style="min-width: 200px;"></v-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span>Save</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin ml-8"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { logout, saveNotification } from "@/lib/utils";

export default {
  name: "MerchantFeeTemplateModal",
  data() {
    return {
      isLoading: false,
      form: {
        Call: "DeployFeeTemplate",
        MerchantID: "",
        APIKey: 1,
        RefundAddress: "AAAAAA",
        output: "json",
      },
      merchantsList: [],
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    onSubmit() {
      if (!this.form.MerchantID) {
        this.$toast.fire("", "Merchant is required", "error");
        return;
      }
      this.isLoading = true;
      const payload = JSON.parse(JSON.stringify(this.form));
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        this.isLoading = false;
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.confirmHook();
          this.close();
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    clickOverlay() {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalMerchantFeeTemplate").modal("hide");
    },
    callback(args) {
      this.merchantsList = args.merchantsList;
      this.confirmHook = args.confirm;
      $("#bsModalMerchantFeeTemplate").modal("show");
    },
  },
  mounted() {
    this.$bus.$on("merchantFeeTemplateModal", this.callback);
  },
  beforeDestroy() {
    this.$bus.$off("merchantFeeTemplateModal", this.callback);
  },
};
</script>

<style scoped></style>
